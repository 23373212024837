import React, {useState, useEffect, useCallback} from 'react';
import {
    Input,
    Button,
    Container,
    Row,
    Col,
    InputGroup
} from 'reactstrap';
import {useNavigate, useParams} from 'react-router-dom';
import Loading from './Loading'; // Import your custom Loading component

const Search = ({
    showSearchInput,
    isNavbar = false
}) => {
    const [query,
        setQuery] = useState('');
    const [results,
        setResults] = useState([]);
    const [loading,
        setLoading] = useState(false);
    const [error,
        setError] = useState(null);
    const API = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const {searchQuery} = useParams();

    const fetchSearchResults = useCallback(async(searchQuery) => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(`${API}/search/?q=${searchQuery}`);
            if (!response.ok) {
                throw new Error('Something went wrong');
            }
            const data = await response.json();
            setResults(data.results); // Set results from the API response
        } catch (error) {
            setError(error.message); // Handle any error from the API
        } finally {
            setLoading(false); // Stop loading spinner once the data is fetched
        }
    }, [API]);

    useEffect(() => {
        if (searchQuery) {
            setQuery(searchQuery); // Update query with searchQuery from URL param
            fetchSearchResults(searchQuery);
        } else {
            setResults([]); // Clear results if searchQuery is not present in the URL
        }
    }, [searchQuery, fetchSearchResults]);

    const handleSearch = () => {
        if (query) {
            navigate(`/search/${query}`); // Navigate with the query
        } else {
            navigate('/search'); // Navigate without query
        }
    };

    // Function to truncate the content and handle non-text elements like tables
    const getTruncatedContent = (content, maxLength = 200) => {
        if (!content) 
            return '';
        
        // Create a temporary element to safely parse and manipulate the HTML content
        const div = document.createElement('div');
        div.innerHTML = content;

        // Remove any unnecessary child elements (e.g., ads, scripts, etc.) We can apply
        // any necessary rules to clean the content here if needed
        const textContent = div.textContent || div.innerText;

        // If the content exceeds the maxLength, truncate it
        if (textContent.length > maxLength) {
            return textContent.substring(0, maxLength) + '...';
        }
        return textContent;
    };

    return (
        <Container
            className={`pt-3 ${isNavbar
            ? 'navbar-search-container'
            : ''}`}>
            {showSearchInput && (
                <Row className="mb-3">
                    <InputGroup className="col-12">
                        <Col xs={12} md={6} lg={9} className="align-items-center">
                            <Input
                                type="text"
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                placeholder="Enter your query..."
                                onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
                                className="me-2"/>
                        </Col>
                        <Col xs={12} md={2} lg={3} className="align-items-center">
                            <Button color="primary" onClick={handleSearch} className="w-100 mt-2 mt-md-0">
                                Search
                            </Button>
                        </Col>
                    </InputGroup>
                </Row>
            )}
            {loading && <Loading/>}
            {/* Display loading spinner while fetching data */}
            {error && <p>Error: {error}</p>}
            {/* Display error message if something goes wrong */}
            <Row>
                {/* Only show "No results found" if search is performed and no results are returned */}
                {results.length === 0 && !loading && !error && !showSearchInput && (
                    <Col xs={12}>
                        <p>No results found.</p>
                    </Col>
                )}
                {/* Render results in horizontal layout */}
                {results.map((post) => (
                    <Col key={post.slug} xs={12} className="mb-1">
                        <div className="search-result d-flex flex-wrap">
                            <div className="search-result-left col-12 col-md-4">
                                {/* Title */}
                                <h5 className="search-result-title">
                                    <a
                                        href={`/${post.class_field.slug}/${post.unit
                                        ? `${post.unit.slug}/`
                                        : ''}${post.skill.slug}/${post.slug}`}
                                        className="text-primary text-decoration-none">
                                        {post.title}
                                    </a>
                                </h5>

                                {/* Post Content */}
                                <p
                                    className="search-result-snippet"
                                    dangerouslySetInnerHTML={{
                                    __html: getTruncatedContent(post.post)
                                }}/>
                            </div>

                            <div className="search-result-right col-12 col-md-8">
                                {/* Display the additional class, unit, and skill names if they exist */}
                                {post.class_field && post.class_field.name && (
                                    <div className="search-result-class">{post.class_field.name}</div>
                                )}
                                {post.unit && post.unit.name && (
                                    <div className="search-result-unit">{post.unit.name}</div>
                                )}
                                {post.skill && post.skill.name && (
                                    <div className="search-result-skill">{post.skill.name}</div>
                                )}
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
            {/* Only show "Enter your query to search for posts" if no query and no results are found */}
            {!searchQuery && results.length === 0 && !showSearchInput && (
                <p>Enter your query to search for posts.</p>
            )}
        </Container>

    );
};

export default Search;
