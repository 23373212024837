import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import Search from '../components/Search';
import selectNavBarData from '../redux/selectors';
import {logoutUser, fetchUser} from '../redux/actions';
import {Nav} from 'reactstrap';

const NavBar = () => {
    const {isLoggedIn, user} = useSelector(selectNavBarData);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isLoggedIn && !user) {
            dispatch(fetchUser());
        }
    }, [isLoggedIn, user, dispatch]);

    const handleLogout = () => {
        dispatch(logoutUser());
    };

    return (
        <Nav
            justified
            className="navbar navbar-dark ng-gradient navbar-expand-lg navbar-light bg-dark d-flex justify-content-between w-100"
            id="mainNav">
            
            <div className="mx-5 container-fluid">
                <Link className="navbar-brand" to="/">
                    HelB
                </Link>
                <div className="d-flex justify-content-center flex-grow-1 align-items-center">
                    <Search
                        className="nav-item"
                        showSearchInput={true}
                        isNavbar={true}/>
                </div>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarResponsive"
                    aria-controls="navbarResponsive"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    Menu
                    <i className="fas fa-bars"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav ms-auto d-flex justify-content-between w-100">
                        <li className="nav-item">
                            <Link className="nav-link" to="/">
                                Home
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/dictionary">
                                Dictionary
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/about">
                                About
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/contact">
                                Contact
                            </Link>
                        </li>
                    </ul>
                    <div className="d-flex align-items-center">
                        {isLoggedIn
                            ? (
                                <div className="dropdown">
                                    <button
                                        className="btn btn-primary dropdown-toggle"
                                        type="button"
                                        id="dropdownMenuButton"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                        {user && user.username}
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        {user && user.is_superuser && (
                                            <li>
                                                <Link className="dropdown-item" to="/admin">
                                                    Dashboard
                                                </Link>
                                            </li>
                                        )}
                                        <li>
                                            <Link className="dropdown-item" to="/profile">
                                                Profile
                                            </Link>
                                        </li>
                                        <li>
                                            <button className="dropdown-item" onClick={handleLogout}>
                                                Logout
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            )
                            : (
                                <Link className="btn btn-primary me-2" to="/login/">
                                    Login
                                </Link>
                            )}
                    </div>
                </div>

            </div>
        </Nav>
    );
};

export default NavBar;