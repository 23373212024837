import React, { useEffect, useState } from 'react';
import { Card, Row, Container } from 'reactstrap';
import Loading from './Loading';

function ClassList() {
    const [classes, setClasses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const API = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchClasses = async () => {
            try {
                const response = await fetch(`${API}/class/`);
                if (!response.ok) {
                    throw new Error('Error fetching classes');
                }
                const data = await response.json();
                setClasses(data);
            } catch (error) {
                setError(error);
                console.error('Error fetching classes:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchClasses();
    }, [API]);

    const renderClasses = () => {
        return (
            <Row className="m-3 justify-content-center text-center">
                {classes.map(classe => (
                    <Card key={classe.id} xs={12} md={6} lg={4} className="my-1 shadow-sm col-md-4">
                        <a href={`/class/${classe.slug}/posts`}>
                            <img
                                src={classe.image}
                                style={{ width: '100%', maxWidth: '190px', height: '150px', objectFit: 'contain' }}
                                className="rounded"
                                variant="top"
                                alt={classe.name}
                            />
                        </a>
                    </Card>
                ))}
            </Row>
        );
    };

    return (
        <Container>
            {loading ? (
                <Loading />
            ) : error ? (
                <Container>
                    <p>Error: {error.message}</p>
                </Container>
            ) : (
                renderClasses()
            )}
        </Container>
    );
}

export default ClassList;