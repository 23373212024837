import React from 'react';
import { Container, Button, Spinner } from 'reactstrap';

const Loading = () => {
    return (
        <Container>
            <Button className="my-5 px-5 btn btn-info btn-lg" style={{display: 'block', margin: '0 auto'}} type="button" disabled>
                <p>Loading...</p>
                <Spinner className="ml-2 mr-2"></Spinner>
            </Button>
        </Container>
    );
}

export default Loading;
